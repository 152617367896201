/// <reference path="../views/Create/Banners/Publish/publish-view-1.html" />
"use strict";

angular
    .module("AccountManagerApp", [
        "ngAnimate",
        "tableSort",
        "ngBootstrap",
        "highcharts-ng",
        "toggle-switch",
        "ngResource",
        "infinite-scroll",
        "ui.bootstrap",
        "cutFilter",
        "bsSelect",
        "ngDebounce",
        "cacheBuster",
        "ngDialog",
        "bf.sortable",
        "ui.bootstrap.datetimepicker"
    ])
    .config([
        "$sceDelegateProvider",
        "$sceProvider",
        "$locationProvider",
        "$provide",
        "$injector",
        function ($sceDelegateProvider, $sceProvider, $locationProvider, $provide, $injector) {
            $sceProvider.enabled(false);
        }
    ])
    .factory("AuthInterceptor", function ($window) {
        return {
            request: function (config) {
                const token = $window["token"];
                const apiUrl = $window["api_url"];
                const bauUrl = $window["bau_url"];
                if (token) {
                    config.headers["Authorization"] = "Bearer " + token;
                }

                if (bauUrl && config.url.match(/api\/adminpanel/)) {
                    config.url = bauUrl + (config.url.startsWith("/") ? "" : "/") + config.url;
                } else if (apiUrl && config.url.match(/^\/?(api\/)/)) {
                    config.url = apiUrl + (config.url.startsWith("/") ? "" : "/") + config.url;
                }

                return config;
            }
        };
    })
    .config(function ($httpProvider) {
        $httpProvider.interceptors.push("AuthInterceptor");
    })
    .run(["$rootScope", "$timeout", "$location", function ($rootScope, $timeout, $location) {}]);

angular.module("AccountManagerApp").factory("Cookie", function () {
    return {
        Create: function (name, value, days) {
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                var expires = "; expires=" + date.toGMTString();
            } else var expires = "";
            document.cookie = name + "=" + value + expires + "; path=/;domain=bannerflow.com";
        },
        Get: function (name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == " ") c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        Delete: function (name) {
            var self = this;
            self.Create(name, "", -1);
        }
    };
});

angular.module("AccountManagerApp").service("UserAgentService", function UserAgentService() {
    return {
        isChrome: function () {
            var sBrowser,
                sUsrAg = navigator.userAgent;

            if (sUsrAg.indexOf("Chrome") > -1) {
                sBrowser = "Google Chrome";
            } else if (sUsrAg.indexOf("Safari") > -1) {
                sBrowser = "Apple Safari";
            } else if (sUsrAg.indexOf("Opera") > -1) {
                sBrowser = "Opera";
            } else if (sUsrAg.indexOf("Firefox") > -1) {
                sBrowser = "Mozilla Firefox";
            } else if (sUsrAg.indexOf("MSIE") > -1) {
                sBrowser = "Microsoft Internet Explorer";
            }
            if (sUsrAg.indexOf("Edge") > -1) {
                sBrowser = "Microsoft Edge";
            }
            if (sBrowser === "Google Chrome") {
                return true;
            } else {
                return false;
            }
        }
    };
});

angular.module("AccountManagerApp").factory("CMPFeatureSet", function () {
    return {
        features: [
            {
                id: "Feeds",
                name: "Feeds",
                icon: "rss",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "AnalyticsDemoData",
                name: "Analytics Demo Data",
                icon: "pie-chart",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "TwoFactorAuthentication",
                name: "Two-factor authentication",
                icon: "lock",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "Studio",
                name: "Studio",
                icon: "edit",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "DisableBannerBuilder",
                name: "Disable Banner builder",
                icon: "edit",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "TagPublishing",
                name: "Publish option - Tags",
                icon: "cloud-upload",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "ApiPublishing",
                name: "Publish option - API",
                icon: "cloud-upload",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "ZipPublishing",
                name: "Publish option - ZIP",
                icon: "cloud-upload",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "Html5ExportPublishing",
                name: "Publish option - HTML5 Export",
                icon: "cloud-upload",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "BrandManagement",
                name: "Brand management",
                icon: "lock",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "Analytics",
                name: "Analytics",
                icon: "line-chart",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "CampaignManager",
                name: "Campaign Manager",
                icon: "rocket",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "UpsellViews",
                name: "Upsell views",
                icon: "unlock-alt",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "SocialCampaignManager",
                name: "Social Campaign Manager",
                icon: "rocket",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "SocialCampaignManagerNetworks.TikTok",
                name: "Social Campaign Manager Networks: TikTok",
                icon: "mobile-phone",
                status: 2,
                dependencies: ["SocialCampaignManager"],
                adminOnly: false,
                active: false
            },
            {
                id: "SocialCampaignManagerNetworks.LinkedIn",
                name: "Social Campaign Manager Networks: LinkedIn",
                icon: "mobile-phone",
                status: 2,
                dependencies: ["SocialCampaignManager"],
                adminOnly: false,
                active: false
            },
            {
                id: "SocialDynamicAdvertising",
                name: "Social Dynamic Advertising",
                icon: "rocket",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "FeedsAutomated",
                name: "Feeds (Automated)",
                icon: "rss",
                status: 2,
                dependencies: ["Feeds"],
                adminOnly: false,
                active: false
            },
            {
                id: "FeedsLive",
                name: "Feeds (Live)",
                icon: "rss",
                status: 2,
                dependencies: ["Feeds", "FeedsAutomated"],
                adminOnly: false,
                active: false
            },
            {
                id: "BannersetDoneMark",
                name: "Mark as done",
                icon: "check",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "AdvancedFormats",
                name: "Advanced formats",
                icon: "cube",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "Scheduling",
                name: "Scheduling",
                icon: "calendar",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "LandingPage",
                name: "Landing Page Editor",
                icon: "file-text",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "TrackingPixels",
                name: "Tracking Pixels",
                icon: "crosshairs",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "EmailMarketing",
                name: "Email Marketing",
                icon: "envelope-o",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "LandingPageEditableCnames",
                name: "Landing Page Cnames",
                icon: "edit",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "AdvancedPasswordPolicy",
                name: "Advanced password policy",
                icon: "lock",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "FallbackSharpness",
                name: "Fallback Sharpness",
                icon: "image",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "WelcomeMessage",
                name: "Welcome message",
                icon: "hand-spock-o",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "AutoOptimization",
                name: "Auto-optimization",
                icon: "cogs",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "ScheduleMigration",
                name: "Schedule Migration",
                icon: "rocket",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "ScheduleMigrationBypassCustomDomainValidation",
                name: "Bypass schedule migration custom domain validation",
                icon: "rocket",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            },
            {
                id: "HideBannerBuilder",
                name: "Hide banner builder list",
                icon: "list-alt",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "StudioAutoTranslate",
                name: "Studio Auto Translate",
                icon: "file",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "StudioTranslationPage",
                name: "Studio Translation Page",
                icon: "file",
                status: 1,
                dependencies: null,
                adminOnly: false,
                active: true
            },
            {
                id: "StudioGenAIImage",
                name: "Studio GenAI Image",
                icon: "file",
                status: 2,
                dependencies: null,
                adminOnly: false,
                active: false
            }
        ]
    };
});
