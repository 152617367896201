﻿angular.module("AccountManagerApp").directive("ngMatch", [
    "$timeout",
    "$parse",
    function ($timeout, $parse, config) {
        return {
            restrict: "A",
            require: "?ngModel",
            link: function link(scope, elem, attrs, ctrl) {
                // if ngModel is not defined, we don't need to do anything
                if (!ctrl) return;
                if (!attrs["ngMatch"]) return;

                var firstPassword = $parse(attrs["ngMatch"]);

                var validator = function (value) {
                    var temp = firstPassword(scope),
                        v = value === temp;
                    ctrl.$setValidity("match", v);
                    return value;
                };

                ctrl.$parsers.unshift(validator);
                ctrl.$formatters.push(validator);
                attrs.$observe("ngMatch", function () {
                    validator(ctrl.$viewValue);
                });

                scope.$watch(attrs.ngMatch, function (newValue, oldValue) {
                    validator(ctrl.$viewValue);
                });
            }
        };
    }
]);

angular.module("AccountManagerApp").directive("appFilereader", function ($q) {
    var slice = Array.prototype.slice;

    return {
        restrict: "A",
        require: "?ngModel",
        scope: {
            theFile: "=theFile",
            callback: "&callback"
        },
        link: function (scope, element, attrs, ngModel) {
            if (!ngModel) return;

            element.files = [];

            if (scope.theFile) element.files.push(scope.theFile);

            ngModel.$render = function () {};

            element.bind("change", function (e) {
                var element = e.target;

                if (element.files.length === 0) return;

                $q.all(slice.call(element.files, 0).map(readFile)).then(function (values) {
                    if (values.length === 0) return;

                    if (element.multiple) {
                        ngModel.$setViewValue(values);

                        scope.theFile = element.files;

                        if (scope.callback) {
                            scope.callback();
                        }
                    } else {
                        ngModel.$setViewValue(values.length ? values[0] : null);

                        scope.theFile = element.files[0];

                        if (scope.callback) {
                            scope.callback();
                        }
                    }
                });

                function readFile(file) {
                    var deferred = $q.defer();

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        deferred.resolve(e.target.result);
                    };
                    reader.onerror = function (e) {
                        deferred.reject(e);
                    };
                    reader.readAsDataURL(file);

                    return deferred.promise;
                }
            }); //change
        } //link
    }; //return
}); //appFilereader
