﻿angular.module("AccountManagerApp").directive("asset", function ($timeout, $rootScope, $location, $q) {
    return {
        priority: 0,
        restrict: "E",
        replace: false,
        scope: {
            text: "@text",
            container: "@container"
        },
        link: function (scope, element, attrs) {
            $("i", element).click(function (e) {
                var i = this;

                console.log(i);

                e.preventDefault();
                e.stopPropagation();
            });
        },
        template:
            "<i style='display: block' data-container='body' data-html='true' data-content='{{ text }}'></i>"
    };
});
