﻿"use strict";

angular.module("bsSelect", []).directive("bsSelect", function ($timeout) {
    var NG_OPTIONS_REGEXP =
        /^\s*(.*?)(?:\s+as\s+(.*?))?(?:\s+group\s+by\s+(.*))?\s+for\s+(?:([\$\w][\$\w\d]*)|(?:\(\s*([\$\w][\$\w\d]*)\s*,\s*([\$\w][\$\w\d]*)\s*\)))\s+in\s+(.*)$/;

    return {
        restrict: "A",
        require: "?ngModel",
        link: function postLink(scope, element, attrs, controller) {
            var options = scope.$eval(attrs.bsSelect) || {},
                selectpicker;

            $timeout(function () {
                element.selectpicker(options);
                selectpicker = element.next(".bootstrap-select");
                selectpicker.removeClass("ng-scope");
            });

            // If we have a controller (i.e. ngModelController) then wire it up
            if (controller) {
                var refresh = function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        element.selectpicker("refresh");
                    }
                };

                var checkValidity = function (value) {
                    if (selectpicker) {
                        selectpicker
                            .toggleClass("ng-invalid", !controller.$valid)
                            .toggleClass("ng-valid", controller.$valid)
                            .toggleClass("ng-invalid-required", !controller.$valid)
                            .toggleClass("ng-valid-required", controller.$valid)
                            .toggleClass("ng-dirty", controller.$dirty)
                            .toggleClass("ng-pristine", controller.$pristine);
                    }
                    return value;
                };

                // Handle AngularJS validation when the DOM changes
                controller.$parsers.push(checkValidity);
                // Handle AngularJS validation when the model changes
                controller.$formatters.push(checkValidity);
                // Handle AngularJS validation when the required attribute changes
                attrs.$observe("required", function () {
                    checkValidity(controller.$viewValue);
                });

                // Watch for changes to the model value
                scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                    refresh(newValue, oldValue);
                });

                // Watch for changes to the options
                if (attrs.ngOptions) {
                    var match = attrs.ngOptions.match(NG_OPTIONS_REGEXP);
                    if (match && scope[match[7]]) {
                        scope.$watch(
                            function () {
                                return scope[match[7]];
                            },
                            function (newValue, oldValue) {
                                if (!angular.equals(newValue, oldValue)) {
                                    refresh(newValue, oldValue);
                                }
                            },
                            true
                        );
                    }
                }
            }
        }
    };
});
