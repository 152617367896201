﻿/*angular.module("toggle-switch", ["ng"]).directive("toggleSwitch", function () { return { restrict: "EA", replace: !0, scope: { model: "=", disabled: "@", onLabel: "@", offLabel: "@", knobLabel: "@" }, template: '<div class="switch" ng-click="toggle()" ng-class="{ \'disabled\': disabled }"><div class="switch-animate" ng-class="{\'switch-off\': !model, \'switch-on\': model}"><span class="switch-left" ng-bind="onLabel"></span><span class="knob" ng-bind="knobLabel"></span><span class="switch-right" ng-bind="offLabel"></span></div></div>', controller: ["$scope", function ($scope) { $scope.toggle = function () { $scope.disabled || ($scope.model = !$scope.model) } }], compile: function (element, attrs) { attrs.onLabel || (attrs.onLabel = "On"), attrs.offLabel || (attrs.offLabel = "Off"), attrs.knobLabel || (attrs.knobLabel = " "), attrs.disabled || (attrs.disabled = !1) } } });*/

(function () {
    var module = angular.module("toggle-switch", ["ng"]);

    module.provider("toggleSwitchConfig", function () {
        this.onLabel = "On";
        this.offLabel = "Off";
        this.knobLabel = "\u00a0";
        this.switching = true;

        var self = this;
        this.$get = function () {
            return {
                onLabel: self.onLabel,
                offLabel: self.offLabel,
                knobLabel: self.knobLabel,
                switching: self.switching
            };
        };
    });

    module.directive("toggleSwitch", function (toggleSwitchConfig) {
        return {
            restrict: "EA",
            replace: true,
            require: "ngModel",
            scope: {
                disabled: "@",
                onLabel: "@",
                offLabel: "@",
                knobLabel: "@",
                switching: "@"
            },
            template:
                '<div role="radio" class="toggle-switch" ng-class="{ \'disabled\': disabled }">' +
                "<div class=\"toggle-switch-animate\" ng-class=\"{'switch-off': !model, 'switch-on': model}\">" +
                '<span class="switch-left" ng-bind="onLabel"></span>' +
                '<span class="knob" ng-bind="knobLabel"></span>' +
                '<span class="switch-right" ng-bind="offLabel"></span>' +
                "</div>" +
                "</div>",
            compile: function (element, attrs) {
                if (!attrs.onLabel) {
                    attrs.onLabel = toggleSwitchConfig.onLabel;
                }
                if (!attrs.offLabel) {
                    attrs.offLabel = toggleSwitchConfig.offLabel;
                }
                if (!attrs.knobLabel) {
                    attrs.knobLabel = toggleSwitchConfig.knobLabel;
                }
                if (!attrs.switching) {
                    attrs.switching = toggleSwitchConfig.switching;
                }

                return this.link;
            },
            link: function (scope, element, attrs, ngModelCtrl) {
                var isEnabled = true;

                attrs.$observe("disabled", function (disabled) {
                    if (disabled === "true") {
                        isEnabled = false;
                    } else {
                        isEnabled = true;
                    }
                });

                element.on("click", function () {
                    scope.$apply(scope.toggle);
                });

                ngModelCtrl.$formatters.push(function (modelValue) {
                    return modelValue;
                });

                ngModelCtrl.$parsers.push(function (viewValue) {
                    return viewValue;
                });

                ngModelCtrl.$render = function () {
                    scope.model = ngModelCtrl.$viewValue;
                };

                scope.toggle = function toggle() {
                    if (isEnabled && attrs.switching == true) {
                        scope.model = !scope.model;
                        ngModelCtrl.$setViewValue(scope.model);
                    }
                };
            }
        };
    });
})();
