﻿angular
    .module("AccountManagerApp")
    .controller(
        "PlacementFinderController",
        function PlacementFinderController($scope, $rootScope, $timeout, $http) {
            $scope.placementFinderModel = {
                query: "",
                loading: false,
                fail: false,
                success: false,
                result: null
            };

            $scope.keyUp = function (event) {
                if (event.which == 13 || event.keyCode == 13) {
                    $scope.findPlacement();
                }
            };

            $scope.getParameterByName = function (name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(location.search);
                return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            };

            $scope.isUrl = function (val) {
                return val === "url" || val === "scheduleUrl" || val === "campaignUrl";
            };

            $scope.findPlacement = function () {
                $scope.placementFinderModel.loading = true;
                $scope.placementFinderModel.fail = false;
                $scope.placementFinderModel.success = false;

                $http({
                    method: "GET",
                    url: "api/v1/adminpanel/placementinfo/" + $scope.placementFinderModel.query
                })
                    .success(function (info) {
                        $scope.placementFinderModel.loading = false;
                        $scope.placementFinderModel.success = true;

                        $scope.placementFinderModel.result = $.map(info, function (v, i) {
                            return { key: i, value: v };
                        });

                        $scope.placementFinderModel.result.unshift({
                            key: "id",
                            value: angular.copy($scope.placementFinderModel.query)
                        });

                        $scope.placementFinderModel.query = "";
                    })
                    .error(function (error) {
                        $scope.placementFinderModel.loading = false;
                        $scope.placementFinderModel.fail = true;
                        $scope.placementFinderModel.query = "";
                    });
            };

            if ($scope.getParameterByName("placementId")) {
                $scope.placementFinderModel.query = $scope.getParameterByName("placementId");
                $scope.findPlacement();
            }
        }
    );
