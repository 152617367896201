﻿"use strict";

/* A service / model */

angular.module("AccountManagerApp").factory("Template", function ($resource) {
    return $resource(
        "{0}/templates/:id".format(API_URL_STATIC),
        {
            id: "@id"
        },
        {
            get: { method: "GET", url: "{0}/templates/:id/".format(API_URL_STATIC) },
            all: { method: "GET", isArray: true, url: "{0}/templates/".format(API_URL_STATIC) },
            post: { method: "POST", url: "{0}/templates/".format(API_URL_STATIC) },
            put: { method: "PUT", url: "{0}/templates/:id/".format(API_URL_STATIC) },
            remove: { method: "DELETE", url: "{0}/templates/:id/".format(API_URL_STATIC) },
            content: { method: "GET", url: "{0}/templates/:id/content".format(API_URL_STATIC) }
        }
    );
});
