﻿var CACHE_BUSTER_RND = Math.random() * 100000000000000000;

angular.module("cacheBuster", []).config([
    "$provide",
    "$httpProvider",
    function ($provide, $httpProvider) {
        $provide.factory("cacheBuster", [
            "$templateCache",
            "$rootScope",
            function ($templateCache, $rootScope) {
                function getBuster() {
                    return Math.random() * 100000000000000000; // BANNERFLOW_VERSION;
                }
                return {
                    request: function (config) {
                        //templateCache safe cache busting (doesnt stomp on angularui's bootstrap)
                        if ($templateCache.get(config.url)) {
                            return config;
                        }
                        var prefix = "?";

                        if (config.url.search("\\?") !== -1) {
                            prefix = "&";
                        }

                        if (config.url.indexOf("/api/v1") === -1) {
                            var cbUrl = config.url + prefix + "cachebuster=" + CACHE_BUSTER_RND;

                            config.url = cbUrl;
                        }

                        return config;
                    }
                };
            }
        ]);

        $httpProvider.interceptors.push("cacheBuster");
    }
]);
