﻿angular
    .module("AccountManagerApp")
    .controller(
        "DownloadBannerController",
        function DownloadBannerController($scope, $rootScope, $timeout, $http) {
            $scope.downloadBannerModel = {
                loading: false
            };

            $scope.downloadBanner = function () {
                window.open(
                    "http://utilities-bannerdownload.azurewebsites.net/api/download/?placementid=" +
                        $scope.downloadBannerModel.placementId,
                    "_blank"
                );
            };
        }
    );
