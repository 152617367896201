﻿"use strict";

angular
    .module("AccountManagerApp")
    .controller(
        "AdminPanelController",
        function AdminPanelController($scope, $rootScope, $timeout, $http) {
            $scope.model = {
                show: "accounts"
            };

            $scope.getParameterByName = function (name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(location.search);
                return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            };

            if ($scope.getParameterByName("placementId")) {
                $scope.model.show = "placement-finder";
            }
        }
    );
