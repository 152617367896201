﻿"use strict";

/* A service / model */

angular.module("AccountManagerApp").factory("User", function ($resource) {
    return $resource(
        "{0}/user/:id".format(API_URL_STATIC),
        {
            id: "@id"
        },
        {
            get: { method: "GET" },
            all: { method: "GET", isArray: true, url: "{0}/user/all".format(API_URL_STATIC) },
            active: { method: "GET", isArray: true, url: "{0}/user/active".format(API_URL_STATIC) },
            findAll: { method: "POST", isArray: true, url: "{0}/user/findall".format(API_URL_STATIC) }
        }
    );
});
