angular
    .module("AccountManagerApp")
    .directive("tagsInput", function ($q, $timeout, $location, $http, AssetStore) {
        return {
            restrict: "E",
            template:
                '<div class="tags-wrapper"><input type="hidden" placeholder="Add a label..."></div>',
            replace: true,
            scope: {
                labels: "@labels"
            },
            require: "?ngModel",
            link: function (scope, element, attrs, ngModel) {
                var drivenByModel = false;

                AssetStore.tags({}, function (data) {
                    var rawData = new Array();

                    _.each(data, function (o) {
                        rawData.push(o);
                    });

                    _.each(ngModel.$viewValue, function (o) {
                        $("input", element).val($("input", element).val() + o + ",");
                    });

                    //element.fadeIn();
                    var tagsinput = $("input", element).tagsinput({
                        typeahead: {
                            source: rawData
                        }
                    });

                    $("input", element).on("change", function (e) {
                        var tags = $("input", element).val().split(",");
                        var saveTags = new Array();

                        _.each(tags, function (tag) {
                            var realTag = _.find(data, function (t) {
                                return t === tag;
                            });

                            if (!realTag) {
                                saveTags.push(tag);
                            } else {
                                saveTags.push(realTag);
                            }
                        });

                        var save = function () {
                            ngModel.$setViewValue(saveTags);
                        };

                        scope.$apply(save);
                    });
                });
            }
        };
    });
