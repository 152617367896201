﻿angular
    .module("AccountManagerApp")
    .controller(
        "CreateAccountDialogController",
        function CreateAccountDialogController($scope, $rootScope, $timeout, $http, CMPFeatureSet) {
            $scope.model = {
                type: $scope.ngDialogData.type,
                name: "",
                done: false,
                loading: false,
                homeUrl: window["homeUrl"],
                createdSlug: "",
                industryOptions: window.viewData.industries,
                industry: null,
                endDate: null,
                impressions: null,
                salesRepOptions: window.viewData.salesRepresentatives,
                customerSuccessManagerOptions: window.viewData.customerSuccessManagers,
                salesRep: null,
                customerSuccessManager: "Not set",
                accountCreated: null
            };

            $scope.createAccount = function () {
                if ($scope.model.industry == null || $scope.model.name == "") return;
                if ($scope.model.type === "trial") {
                    if (
                        ($scope.model.endDate === null,
                        $scope.model.impressions === null,
                        $scope.model.salesRep === null)
                    )
                        return;
                }
                $scope.model.loading = true;

                const shouldSetCMPFeatures =
                    $scope.model &&
                    ($scope.model.type === "enterprise" || $scope.model.type === "trial");

                if ($scope.model.name != "") {
                    var data = {
                        name: $scope.model.name,
                        industry: $scope.model.industry,
                        trialendDate: $scope.model.endDate,
                        totalAmountOfImpressions: $scope.model.impressions,
                        salesRep: $scope.model.salesRep,
                        plan: $scope.model.type,
                        customerSuccessManager: $scope.model.customerSuccessManager
                    };

                    if (shouldSetCMPFeatures) {
                        $http({
                            method: "POST",
                            url:
                                $scope.model.type === "trial"
                                    ? "/api/adminpanel/trial"
                                    : "/api/adminpanel/accounts",
                            data: data
                        }).success(function (data) {
                            $scope.model.accountCreated = $scope.model.homeUrl + "/" + data.slug;
                            $scope.model.createdSlug = data.slug;
                            $scope.model.loading = false;
                            $scope.model.done = true;

                            $http({
                                method: "POST",
                                url: "/api/adminpanel/accounts/" + data.id + "/set-features",
                                data: CMPFeatureSet
                            });
                        });
                    }
                }
            };
        }
    );
