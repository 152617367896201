﻿"use strict";

/* Controllers */
var _START_SHOWING_COUNT = 100;
var _CURRENT_SHOWING_COUNT = 100;
var _MAX_SHOWING = false;

angular
    .module("AccountManagerApp")
    .controller(
        "PanelController",
        function PanelController($scope, $rootScope, $timeout, $http, ngDialog) {
            $scope.panelModel = {
                panel: null,
                type: $scope.ngDialogData.type || "new",
                addToAll: false,
                panelFields: new Array()
            };

            $scope.getFields = function (fillWithData) {
                var x = _.find($scope.$parent.model.panelTypes, function (pt) {
                    return pt.type === $scope.panelModel.panel.type;
                });

                if (fillWithData && $scope.panelModel.panel.data) {
                    for (var i in x.fields) {
                        x.fields[i].value = $scope.panelModel.panel.data[x.fields[i].title];
                    }
                }

                $scope.panelModel.panelFields = x.fields;
            };

            $scope.updateFieldValues = function () {
                $scope.panelModel.panel.data = {};
                for (var i in $scope.panelModel.panelFields) {
                    $scope.panelModel.panel.data[$scope.panelModel.panelFields[i].title] =
                        $scope.panelModel.panelFields[i].value;
                }
            };

            $scope.$watch("panelModel.panel.type", function (n, o) {
                if (n === o) return;

                if ($scope.panelModel.type === "new") {
                    $scope.getFields();
                }
            });

            $scope.panelModel.panel = $scope.ngDialogData.panel
                ? angular.copy($scope.ngDialogData.panel)
                : { type: 2 };

            $scope.update = function () {
                $scope.updateFieldValues();

                $scope.closeThisDialog({ type: "update", panel: $scope.panelModel.panel });
            };

            $scope.add = function () {
                var type = _.find($scope.$parent.model.panelTypes, function (pt) {
                    return pt.type === $scope.panelModel.panel.type;
                });

                $scope.panelModel.panel.name = type.name;

                $scope.updateFieldValues();

                $scope.closeThisDialog({
                    type: "new",
                    panel: $scope.panelModel.panel,
                    addAll: $scope.panelModel.addToAll
                });
            };

            if ($scope.panelModel.type != "new") {
                $scope.getFields(true);
            } else {
                $scope.getFields();
            }
        }
    );
