﻿angular
    .module("AccountManagerApp")
    .directive("helpIcon", function ($timeout, $rootScope, $location, $q) {
        return {
            priority: 0,
            restrict: "E",
            replace: false,
            scope: {
                text: "@text",
                container: "@container"
            },
            link: function (scope, element, attrs) {
                $("i", element).popover({
                    trigger: "manual",
                    container: scope.container ? element.closest(scope.container) : element.parent()
                });

                $("i", element).click(function (e) {
                    var i = this;
                    $(i).popover("show");

                    //e.preventDefault();
                    //e.stopPropagation();

                    $timeout(function () {
                        $(document).one("click", function () {
                            $(i).popover("hide");
                        });
                    }, 100);

                    e.preventDefault();
                    e.stopPropagation();
                });
            },
            template:
                "<i style='width: 15px;' class='help-icon fa fa-question-circle' data-container='body' data-toggle='popover' data-placement='top' data-html='true' data-content='{{ text }}'></i>"
        };
    });
