﻿"use strict";

angular.module("AccountManagerApp").filter("percentage", function () {
    return function (input) {
        var rounded = Math.round(input * 10000) / 100;
        if (rounded == NaN) {
            return "";
        }
        var percentage = "" + rounded + "%";
        return percentage;
    };
});

angular.module("AccountManagerApp").filter("millSecondsToTimeString", function () {
    return function (millseconds) {
        var seconds = Math.floor(millseconds / 1000);
        var days = Math.floor(seconds / 86400);
        var hours = Math.floor((seconds % 86400) / 3600);
        var minutes = Math.floor(((seconds % 86400) % 3600) / 60);
        var timeString = "";
        if (days > 0) timeString += days > 1 ? days + " days " : days + " day ";
        if (hours > 0) timeString += hours > 1 ? hours + " hours " : hours + " hour ";
        if (minutes >= 0) timeString += minutes > 1 ? minutes + " minutes " : minutes + " minute ";
        return timeString;
    };
});

angular.module("cutFilter", []).filter("cut", function () {
    return function (value, wordwise, max, tail) {
        if (!value) return "";

        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;

        value = value.substr(0, max);
        if (wordwise) {
            var lastspace = value.lastIndexOf(" ");
            if (lastspace != -1) {
                value = value.substr(0, lastspace);
            }
        }

        return value + (tail || " …");
    };
});

angular.module("AccountManagerApp").filter("numberPro", [
    "$filter",
    function ($filter) {
        return function (input) {
            if (!isNaN(input) && (input || input == 0)) {
                return $filter("number")(input).replace(/,/g, "\u2009"); //Replace commas with thinspaces
            }
        };
    }
]);

angular.module("AccountManagerApp").filter("currencyPro", [
    "$filter",
    function ($filter) {
        return function (input) {
            input = parseFloat(input);
            if (input % 1 === 0) {
                input = input.toFixed(0);
            } else input = input.toFixed(2);

            return "$" + $filter("numberPro")(input);
        };
    }
]);
