﻿"use strict";

/* A service / model */

angular.module("AccountManagerApp").factory("Account", function ($resource) {
    return $resource(
        "{0}/accounts/:id".format(API_URL_STATIC),
        {
            id: "@id"
        },
        {
            slugs: { method: "GET", isArray: true, url: "{0}/accounts/slugs".format(API_URL_STATIC) }
        }
    );
});
